/*
Theme Name: Ultras
Theme URI: https://templatesjungle.com/
Author: TemplatesJungle
Author URI: https://templatesjungle.com/
Description: Ultras is specially designed product packaged for eCommerce store websites.
Version: 1.1
*/

/*--------------------------------------------------------------
This is main CSS file that contains custom style rules used in this template
--------------------------------------------------------------*/

/*------------------------------------*\
    Table of contents
\*------------------------------------*/

/*------------------------------------------------

CSS STRUCTURE:

1. VARIABLES

2. GENERAL TYPOGRAPHY
  2.1 General Styles
  2.2 Floating & Alignment
  2.3 Forms
  2.4 Lists
  2.5 Code
  2.6 Tables
  2.7 Spacing
  2.8 Utilities
  2.9 Misc
    - Row Border
    - Zoom Effect
  2.10 Buttons
    - Button Sizes
    - Button Shapes
    - Button Color Scheme
    - Button Aligns
  2.11 Section
    - Hero Section
    - Section Title
    - Section Paddings
    - Section Margins
    - Section Bg Colors
    - Content Colors
    - Content Borders

3. EXTENDED TYPOGRAPHY
  3.1 Blockquote / Pullquote
  3.2 Text Highlights

4. CONTENT ELEMENTS
  4.1 Tabs
  4.2 Accordions
  4.3 Brand Carousel
  4.4 Category Carousel

5. BLOG STYLES
  5.1 Blog Single Post
  5.2 About Author
  5.3 Comments List
  5.4 Comments Form3

6. SITE STRUCTURE
  6.1 Header
    - Header Menu
    - Nav Sidebar
  6.2 Billboard
  6.3 About Us Section
  6.4 Video Section
  6.5 Selling Products Section
  6.6 Quotation Section
  6.7 Latest Blogs Section
  6.8 Newsletter Section
  6.9 Instagram Section
  6.10 Footer
    - Footer Top
    - Footer Bottom

7. OTHER PAGES
  7.1 Product detail
  7.2 Shop page

    
/*--------------------------------------------------------------
/** 1. VARIABLES
--------------------------------------------------------------*/
:root {
    /* widths for rows and containers
     */
    --header-height       : 160px;
    --header-height-min   : 80px;
}
/* on mobile devices below 600px
 */
@media screen and (max-width: 600px) {
    :root {
        --header-height : 100px;
        --header-height-min   : 80px;
    }
}
/* Theme Colors */
:root {
    --accent-color       : #FFC43F;
    --dark-color         : #222222;
    --light-dark-color   : #727272;
    --light-color        : #fff;
    --grey-color         : #dbdbdb;
    --light-grey-color   : #fafafa;
    --primary-color      : #6995B1;
    --light-primary-color   : #eef1f3;
}

/* Fonts */
:root {
    --body-font           : "Open Sans", sans-serif;
    --heading-font        : "Nunito", sans-serif;
}

body {
  --bs-link-color: #333;
  --bs-link-hover-color:#333;

  --bs-link-color-rgb: 40,40,40;
  --bs-link-hover-color-rgb: 0,0,0;

  /* --bs-link-color: #FFC43F;
  --bs-link-hover-color: #FFC43F; */

  --bs-light-rgb: 248, 248, 248;

  --bs-font-sans-serif: "Open Sans", sans-serif;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 2;
  --bs-body-color: #747474;

  --bs-primary: #FFC43F;
  --bs-primary-rgb: 255, 196, 63;
  
  --bs-primary-bg-subtle: #FFF9EB;
  --bs-border-color: #F7F7F7;

  --bs-secondary-rgb: 230, 243, 251;

  --bs-success-rgb: 238, 245, 228;
  --bs-danger-rgb: 249, 235, 231;
  --bs-warning-rgb: 255, 249, 235;
  --bs-info-rgb: 230, 243, 250;

  /*--bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13,110,253;
  --bs-secondary-rgb: 108,117,125;
  --bs-success-rgb: 25,135,84;
  --bs-info-rgb: 13,202,240;
  --bs-warning-rgb: 255,193,7;
  --bs-danger-rgb: 220,53,69;
  --bs-light-rgb: 248,249,250;
  --bs-dark-rgb: 33,37,41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;

  --bs-body-color: #212529;
  --bs-body-color-rgb: 33,37,41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255,255,255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0,0,0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33,37,41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233,236,239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33,37,41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248,249,250;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13,110,253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10,88,202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;*/
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ffc43f;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f7a422;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ffc43f;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d3d7dd;
  --bs-btn-disabled-border-color: transparent;
}
.btn-outline-primary {
  --bs-btn-color: #ffc43f;
  --bs-btn-border-color: #ffc43f;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff3cd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff3cd;
  --bs-gradient: none;
}
.btn-outline-light {
  --bs-btn-color: #747474;
  --bs-btn-border-color: #EFEFEF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #EFEFEF;
  --bs-btn-hover-border-color: #EFEFEF;
  --bs-btn-focus-shadow-rgb: 248,249,250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #EFEFEF;
  --bs-btn-active-border-color: #EFEFEF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #EFEFEF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EFEFEF;
  --bs-gradient: none;
}
.btn-warning {
  --bs-btn-color: #747474;
  --bs-btn-bg: #FCF7EB;
  --bs-btn-border-color: #FCF7EB;
  --bs-btn-hover-color: #747474;
  --bs-btn-hover-bg: #FFECBE;
  --bs-btn-hover-border-color: #FFECBE;
  --bs-btn-focus-shadow-rgb: 217,164,6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFECBE;
  --bs-btn-active-border-color: #FFECBE;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FCF7EB;
  --bs-btn-disabled-border-color: #FCF7EB;
}
.btn-success {
  --bs-btn-color: #222;
  --bs-btn-bg: #EEF5E4;
  --bs-btn-border-color: #EEF5E4;
  --bs-btn-hover-color: #222;
  --bs-btn-hover-bg: #9de3c2;
  --bs-btn-hover-border-color: #9de3c2;
  --bs-btn-focus-shadow-rgb: 60,153,110;
  --bs-btn-active-color: #222;
  --bs-btn-active-bg: #9de3c2;
  --bs-btn-active-border-color: #9de3c2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #222;
  --bs-btn-disabled-bg: #EEF5E4;
  --bs-btn-disabled-border-color: #EEF5E4;
}
.btn-danger {
  --bs-btn-color: #222;
  --bs-btn-bg: #FFEADA;
  --bs-btn-border-color: #FFEADA;
  --bs-btn-hover-color: #222;
  --bs-btn-hover-bg: #ecc9af;
  --bs-btn-hover-border-color: #ecc9af;
  --bs-btn-focus-shadow-rgb: 60,153,110;
  --bs-btn-active-color: #222;
  --bs-btn-active-bg: #ecc9af;
  --bs-btn-active-border-color: #ecc9af;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #222;
  --bs-btn-disabled-bg: #FFEADA;
  --bs-btn-disabled-border-color: #FFEADA;
}
body {
  letter-spacing: 0.03em;
}
h1,h2,h3,h4,h5,h6 {
  font-family: var(--heading-font);
  color: var(--dark-color);
  font-weight: 700;
}
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  font-weight: 700;
}
.breadcrumb.text-white {
  --bs-breadcrumb-divider-color: #fff;
  --bs-breadcrumb-item-active-color: var(--bs-primary);
}
.dropdown-menu {
  --bs-dropdown-link-active-bg: var(--bs-primary);
}
/*----------------------------------------------*/
/* 6. SITE STRUCTURE */
/*----------------------------------------------*/
/* 6.1 Header
--------------------------------------------------------------*/
/* Preloader */
.preloader-wrapper {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 111;
  background: #fff;
}

.preloader-wrapper .preloader {
  margin: 20% auto 0;
  transform: translateZ(0);
}

.preloader:before,
.preloader:after {
  content: '';
  position: absolute;
  top: 0;
}

.preloader:before,
.preloader:after,
.preloader {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: animation 1.2s infinite ease-in-out;
}

.preloader {
  animation-delay: -0.16s;
}

.preloader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.preloader:after {
  left: 3.5em;
}

@keyframes animation {
  0%,
  80%,
  100% {
    box-shadow: 0 2em 0 -1em var(--accent-color);
  }
  40% {
    box-shadow: 0 2em 0 0 var(--accent-color);
  }
}

/* *** Start editing below this line *** */
/*.search-bar {
  background: #F9F9F9;
  border: 1px solid #F8F8F8;
  border-radius: 4px;
  max-width: fit-content;
  padding-right: 20px;
}
.search-bar ::placeholder {
  color: #9D9D9D;
}
.search-bar select,
.search-bar input,
.search-bar form {
  margin-bottom: 0;
}
.search-bar select {
  border: none;
}
.search-bar input[type="text"] {
  border: none;
  width: 434px;
}
*/
/* support-box */
/*.support-box h3 {
  margin: 0;
}*/
/* .stellarnav ul */
/*.stellarnav ul {
  text-align: left;
}
.stellarnav>ul>li>a {
  padding: 10px;
}*/
.container-fluid {
  max-width: 1600px;
}
.banner-blocks {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 2rem;
}
.block-1 { grid-area: 1 / 1 / 3 / 8; }
.block-2 { grid-area: 1 / 8 / 2 / 13; }
.block-3 { grid-area: 2 / 8 / 3 / 13; }

@media screen and (max-width:1140px) {
  .banner-blocks {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
  .block-1 { grid-area: 1 / 1 / 3 / 2; }
  .block-2 { grid-area: 3 / 1 / 4 / 2; }
  .block-3 { grid-area: 4 / 1 / 5 / 2; }
  
}

/* banner ad */
.banner-vlad {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  display: block !important;
}
.banner-vlad.blue {
  background: #e6f3fb;
}
.banner-vlad .swiper-pagination {
  bottom: 40px;
  left: 0;
  right: 0;
}
.banner-vlad .swiper-pagination .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  margin: 4px;
  background: #FFF;
  opacity: 1;
  transition: background 0.3s ease-out;
}
.banner-vlad .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background: #FFC43F;
}
.banner-vlad .swiper-slide {
  min-height: 630px;
  display: flex;
}
/* .banner-vlad .banner-content {
  padding: 52px;
}
.banner-vlad.large .banner-content {
  padding: 90px;
} */
.banner-vlad .banner-content .categories {
  font-family: 'Garamond';
  font-size: 37px;
  text-transform: capitalize;
  color: var(--dark-color);
}
.banner-vlad .banner-content .sale {
  position: relative;
  display: inline-block;
}
.banner-vlad .banner-content .sale:before {
  content: '';
  width: 80px;
  border-bottom: 1px solid #111;
  position: absolute;
  bottom: 6px;
}
.banner-vlad .banner-content .sale:after {
  content: 'SALE';
  font-family: var(--body-font);
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #252525;
  bottom: 0;
  right: 0;
}
.banner-vlad .banner-content .banner-title {
  letter-spacing: 0.02em;
  font-size: 33px;
}
.banner-vlad.large .banner-content .categories {
  color: var(--accent-color);
}
.banner-vlad.large .banner-content .banner-title {
  font-size: 54px;
}

/* Swiper carousel */
.swiper-prev,
.swiper-next {
  width: 38px;
  height: 38px;
  line-height: 38px;
  background: #F1F1F1;
  color: #222222;
  padding: 0;
  text-align: center;
  border-radius: 10px;
  --bs-btn-border-color: transparent;
  --bs-btn-active-bg: #ec9b22;
  --bs-btn-active-border-color: transparent;
  --bs-btn-hover-bg: #FFC43F;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-disabled-color: #ccc;
  --bs-btn-disabled-bg: #eaeaea;
  --bs-btn-disabled-border-color: #eaeaea
}
.swiper-prev:hover,
.swiper-next:hover {
  background: #FFC43F;
}
.btn-link {
  margin-right: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  text-transform: capitalize;
  color: #787878;
}
/* category carousel */
.category-carousel .category-item {
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  text-align: center;
  padding: 60px 20px;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
}
.category-carousel .category-item:hover {
  transform: translate3d(0,-10px,0);
  box-shadow: 0px 21px 44px rgba(0, 0, 0, 0.08);
}
.category-carousel .category-item .category-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #222222;
  margin-top: 20px;
}

/* brand carousel */
.brand-carousel .brand-item {
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 16px;
}
.brand-carousel .brand-item img {
  width: 100%;
  border-radius: 12px;
}
.brand-carousel .brand-item .brand-details {
  margin-left: 15px;
}
.brand-carousel .brand-item .brand-title {
  margin: 0;
}

/* product tabs */
.product-tabs .nav-tabs {
  justify-content: flex-end;
  border: none;
  
  --bs-nav-link-hover-color: #111;
  --bs-nav-link-color: #555;
  --bs-nav-tabs-link-border-color: #fff;
  --bs-nav-tabs-link-hover-border-color: transparent;
  --bs-nav-tabs-link-active-border-color: #fff;
}
.product-tabs .nav-tabs .nav-link.active, 
.product-tabs .nav-tabs .nav-item.show .nav-link {
  /* border: none; */
  border-bottom: 3px solid var(--accent-color);
}

/* product-grid */
/* .product-grid {
  gap: 25px;
} */
.product-item {
  position: relative;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease-out;
  
}
.product-item:hover {
  box-shadow: 0px 21px 44px rgba(0, 0, 0, 0.08);
}
.product-item h3 {
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #333333;
  margin: 0;
}
.product-item figure {
  /* background: #F9F9F9; */
  border: #f9f9f9 4px solid;
  border-radius: 12px;
  text-align: center;
  overflow: hidden;
}
.product-item figure img {
  max-height: 210px;
  min-height: 210px;
  height: auto;
}
.product-item .btn-wishlist {
  position: absolute;
  top: 30px;
    right: 30px;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #d8d8d8;
  transition: all 0.3s ease-out;
}
.product-item .btn-wishlist:hover, .red-hover {
  background: rgb(240, 56, 56) !important;
  color: #fff;
}
.product-item .qty {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #9D9D9D;
}
.product-item .rating {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-transform: capitalize;
  color: #222222;
}
.product-item .rating iconify-icon {
  color: #FFC43F;
}
.product-item .price {
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-transform: capitalize;
  color: #222222;
}
.product-item .product-qty {
  width: 85px;
}
.product-item .btn-link {
  text-decoration: none;
}
.product-item #quantity {
  height: auto;
  width: 28px;
  text-align: center;
  border: none;
  margin: 0;
  padding: 0;
}
.product-item .btn-number {
  width: 26px;
  height: 26px;
  line-height: 1;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 6px;
  color: #222;
  padding: 0;
}

/* floating image */
.image-float {
  margin-top: -140px;
  margin-bottom: -140px;
}
@media screen and (max-width:991px) {
  .image-float {
    margin: 0;
  }
}
 /* post item */
.post-item .post-meta {
  font-size: 0.8rem;
  line-height: 1;
}
.post-item .post-meta svg {
  margin-right: 5px;
}

@media screen and (max-width: 991px) {
  /* offcanvas menu */
  .offcanvas-body .nav-item {
    font-weight: 700;
    border-bottom: 1px solid #d1d1d1;
  }
  .offcanvas-body .filter-categories {
    width: 100%;
    margin-bottom: 20px !important;
    border: 1px solid #d1d1d1 !important;
    padding: 14px;
    border-radius: 8px;
  }
  /* dropdown-menu */
  .dropdown-menu {
    padding: 0;
    border: none;
    line-height: 1.4;
    font-size: 0.9em;
  }
  .dropdown-menu a {
    padding-left: 0;
  }
  .dropdown-toggle::after {
    position: absolute;
    right: 0;
    top: 21px;
  }
}


