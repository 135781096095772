.spicontent{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.spinner {
    width: 60px;
    height: 60px;
    position: relative;
   }
   
   .spinner .dot {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
   }
   
   .spinner .dot::after {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgb(12, 180, 231);
   }
   
   @keyframes spin {
    to {
     transform: rotate(360deg);
    }
   }
   
   .spinner .dot {
    animation: spin 2s infinite;
   }
   
   .spinner .dot:nth-child(2) {
    animation-delay: 100ms;
   }
   
   .spinner .dot:nth-child(3) {
    animation-delay: 200ms;
   }
   
   .spinner .dot:nth-child(4) {
    animation-delay: 300ms;
   }
   
   .spinner .dot:nth-child(5) {
    animation-delay: 400ms;
   }
   .hidder{
    display: none !important;
   }
   .banner-vlad {
    display: block !important;
}