.current{
  font-weight: bold;
  color: #151515;
}
.nav-links:hover{
  cursor: pointer;
}
a{
  text-decoration: none !important;
}
.rfces{
  width: fit-content;
  background-color: rgb(243, 195, 74);
  border: none;
  border-radius: .5rem;
  padding: none !important;
  text-decoration: none !important;
}
.display-none{
  display: none;
}
.display-block{
  display: block;
}
.white{
  color: var(--accent-color) !important;
}
.qushb{
  height: 9rem !important;
}
.srcsrc{
  display: none;
}
@media screen and (min-width: 992px) {
  .srcsrc{
    position: fixed;
    top: 4.5rem;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
  }
  .srcsrc ul ul{
    border: 1px solid var(--bs-light-rgb);
  }
}